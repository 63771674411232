<template>
  <div class="">
    <div v-if="!isEditable" role="button" class="badge rounded py-half" :class="`badge-light-${hasZeroValue ? 'danger' : ''}`">
      <span class="" style="font-size: 14px">
        {{ metrics.dimHeight }}  &times; {{ metrics.dimWidth }}  &times; {{ metrics.dimLength }} in
         <!-- & {{ metrics.weight }} lb -->
      </span>
      <p-icon :color="hasZeroValue ? 'danger' : 'primary'" size="16px" name="bi-pencil-square" class="m-half" @click.native="isEditable = true" />
    </div>
    <div v-else>
      <div>
        <div class="d-flex align-items-start">
          <dx-util-number-box v-model="metrics.dimLength" type="default"
            label="Length" format="#0 in"
            class="mr-half" :min="1"
          >
            <dx-util-validator>
              <dx-util-range-rule :min="0.1" message="Length is required field" />
            </dx-util-validator>
          </dx-util-number-box>
          <dx-util-number-box v-model="metrics.dimHeight" type="default"
            label="Height" format="#0 in"
            class="mr-half" :min="1"
          >
            <dx-util-validator>
              <dx-util-range-rule :min="0.1" message="Height is required field" />
            </dx-util-validator>
          </dx-util-number-box>
          <dx-util-number-box v-model="metrics.dimWidth" type="default"
            label="Width" format="#0 in"
            class="mr-half" :min="1"
          >
            <dx-util-validator>
              <dx-util-range-rule :min="0.1" message="Width is required field" />
            </dx-util-validator>
          </dx-util-number-box>
          <dx-util-number-box v-if="false" v-model="metrics.weight"
            type="default"
            label="Weight" format="#0.0 lb"
            class="mr-half" :step="0.1"
          >
            <dx-util-validator>
              <dx-util-range-rule :min="0.1" message="Weight is required field" />
            </dx-util-validator>
          </dx-util-number-box>
          <dx-util-button text="Save" type="success" class="mt-half mr-half" @click="updateMetrics" />
          <dx-util-button text="Cancel" type="danger" class="mt-half" @click="cancelUpdate" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productsService from '@/http/requests/product/productsService'
import useFbmState from '../useFbmStates'

export default {
  props: {
    weight: {
      type: [Number, String],
      default: 0,
    },
    height: {
      type: [Number, String],
      default: 0,
    },
    width: {
      type: [Number, String],
      default: 0,
    },
    length: {
      type: [Number, String],
      default: 0,
    },
    productId: {
      type: [Number, String],
      required: true,
    },
  },
  setup() {
    const { preventShipmentSave } = useFbmState()
    return {
      preventShipmentSave,
    }
  },
  data() {
    return {
      metrics: {
        // weight: this.weight,
        dimHeight: parseFloat(this.height),
        dimWidth: parseInt(this.width, 10),
        dimLength: parseInt(this.length, 10),
        productId: parseInt(this.productId, 10),
      },
      isEditable: false,
    }
  },
  computed: {
    hasZeroValue() {
      const result = Object.values(this.metrics).some(v => v === 0)
      return result
    },
  },
  watch: {
    'currentOrder.id': {
      immediate: true,
      deep: true,
      handler() {
        this.preventShipmentSave = []
      },
    },
    hasZeroValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          if (!this.preventShipmentSave.includes(this.productId)) {
            this.preventShipmentSave.push(this.productId)
          }
        }
      },
    },
  },
  methods: {
    async updateMetrics() {
      this.isEditable = false
      this.metrics.id = this.productId
      await productsService.update(this.metrics)
      this.preventShipmentSave = this.preventShipmentSave.filter(el => el !== this.productId)
    },
    cancelUpdate() {
      // this.metrics.weight = this.weight
      this.dimHeight = this.height
      this.dimLength = this.length
      this.dimWidth = this.width
      this.isEditable = false
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
